$(function () {
    var lazyLoadElement;

    $.ajaxSetup({
        cache: true
    });

    /* */

    console.log('powered by networker.de');

    /* function for reels Element */
    $(document).ready(function(){

        $('button.more_less').on('click', function (target) {
            if($(this).closest(".reelsElement").hasClass('active')){
                $(this).closest(".reelsElement").removeClass('active');
                console.log("deactivated");
            } else {
                $(this).closest(".reelsElement").addClass('active');
                console.log("activated");
            }
        });
    });

    /* initialize FlexNav */

    $(".flexnav").flexNav({
        'type': 'slide'
    });

    $(window).on('scroll', function () {
        if (lazyLoadElement) {
            clearTimeout(lazyLoadElement);
        }
        lazyLoadElement = setTimeout(function () {
            $('div[data-lazy]').each(function () {
                posBottom = $(window).height() + $(window).scrollTop();
                offset = $(this).offset().top;
                if (posBottom + 150 > offset) {
                    var parent = $(this);
                    $.ajax({
                        type: "GET",
                        url: location.href + '?type=91&uid=' + $(this).data('lazy'),
                        success: function (result) {
                            $(parent).removeAttr('data-lazy');
                            $(parent).parent().replaceWith($(result).find('page').find('content').html());
                        },
                        error: function (result) {
                        }
                    });
                }
            });
        }, 200);
    }).trigger('scroll');

    /* cookieBanner */

    /* history */

    if ($('.history').length) {

        $('.history .circle').each(function () {

            var current = this;

            // init controller
            var circles = new ScrollMagic.Controller();

            // build scenes
            var scene = new ScrollMagic.Scene({ triggerElement: current, duration: 0 })
                .setClassToggle(current, 'green') // add class toggle
                //.addIndicators() // add indicators (requires plugin)
                .addTo(circles);
        });

        // init controller
        var line = new ScrollMagic.Controller();

        // build scene
        var scene = new ScrollMagic.Scene({ triggerElement: ".line.green", duration: $('.history').height() })
            .addTo(line)
            //.addIndicators() // add indicators (requires plugin)
            .on("progress", function (e) {
                $(".line.green").css("height", (e.progress.toFixed(3) * 100) + '%');
            });
    }

    /* footer accordion */

    enquire.register("screen and (max-width: 599px)", {

        match: function () {
            $('#navFooter > ul > li > ul').hide();

            $('#navFooter > ul > li > a').on('click', function (e) {
                e.preventDefault();
                $(this).toggleClass('open');
                $(this).next('ul').slideToggle();
            });
        },

        unmatch: function () {
            $('#navFooter > ul > li > ul').show();
            $('#navFooter > ul > li > a').off('click').removeClass('open');
        }

    });

    /* header slider */
    var swiperChange = false;
    var swiper = new Swiper('.slider .swiper-container', {
        loop: true,
        slidesPerView: 1,
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        autoplay: false,
        on: {
            slideChangeTransitionStart: function () {
            },
            slideChangeTransitionEnd: function () {
                clearTimeout(swiperChange);
                if ($('.swiper-slide-active').find('.video').length) {
                    clearTimeout(swiperChange)
                    swiperChange = setTimeout(function () {
                        swiper.slideNext(1500);
                    }, 30000);
                } else {
                    swiperChange = setTimeout(function () {
                        swiper.slideNext(1500);
                    }, 5000);
                }
            }
        },
        // autoplay: {
        //     delay: 5000,
        // },
    });

    /* content slider */
    var contentSlider = new Swiper('.contentSlider .swiper-container', {
        loop: true,
        slidesPerView: 1,
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        autoplay: false,
        // on: {
        //     slideChangeTransitionStart: function () {
        //     },
        //     slideChangeTransitionEnd: function () {
        //         clearTimeout(swiperChange);
        //         if ($('.swiper-slide-active').find('.video').length) {
        //             console.log('video');
        //             clearTimeout(swiperChange)
        //             swiperChange = setTimeout(function () {
        //                 swiper.slideNext(1500);
        //             }, 30000);
        //         } else {
        //             console.log('no video');
        //             swiperChange = setTimeout(function () {
        //                 swiper.slideNext(1500);
        //             }, 5000);
        //         }
        //     }
        // },
        // autoplay: {
        //     delay: 5000,
        // },
    });


    /*contactBox */
    $('.contactBox .contactButton').on('click', function () {
        $('.contactBox').toggleClass('active');
    });

    $('html').click(function (event) {
        if ($(event.target).closest('.contactBox').length === 0) {
            $('.contactBox').removeClass('active');
        }
        if ($(event.target).closest('#language').length === 0) {
            $('#language .current').siblings().hide();
        }
    });


    /* form select 2 */
    $(".form-group select").select2({
        placeholder: 'Bitte wählen',
        minimumResultsForSearch: Infinity,
        width: "style"
    });

    /* tabElement */
    enquire.register("screen and (min-width:320px)", {

        match: function () {
            $(".accordeon dd").hide();

            $('.accordeon dt').on('click', function () {
                $(this).toggleClass('active');
                $(this).next('dd').slideToggle();
            });
        },

    });

    /* hotSpots */
    enquire.register("screen and (min-width: 700px)", {

        match: function () {

            $('.netHotSpots .hotSpot').addClass('hide');
            $('.netHotSpots').removeClass('active');

            $(document).on('click touchstart', function (event) {
                if (!$(event.target).closest('.hotSpot').length) {
                    if ($('.netHotSpots').hasClass("active")) {
                        $('.netHotSpots').removeClass('active');
                        $('.netHotSpots .hotSpot').addClass('hide');
                    }
                }
            });

            $('.netHotSpots .hotSpot').on('click', function (e) {
                event.stopPropagation();
            });

            $('.netHotSpots .hotSpotArea .number').on('click', function (e) {

                $('.netHotSpots .hotSpot').addClass('hide');
                $('.netHotSpots').removeClass('active');

                $('.netHotSpots').addClass('active');
                $(this).closest('.entry').find('.hotSpot:eq(' + $(this).index() + ')').removeClass('hide');
                e.stopPropagation();

            });

            $('.netHotSpots .close').on('click', function (e) {

                $('.netHotSpots').removeClass('active');
                $('.netHotSpots .hotSpot').addClass('hide');
                e.stopPropagation();

            });

        },

        unmatch: function () {

            $('.netHotSpots .hotSpot').removeClass('hide');
            $('.netHotSpots .number, .hotSpots .hotSpot, .hotSpots .close').off('click');

        }

    });


    /* file fields */
    /* activate special styling for file inputs */
    $(".fileupload").each(function () {
        var label = $(this).find('label');
        label.clone().addClass('overlayLabel').text('').insertAfter(label);
    });

    $('input[type=file]').on('change', function () {
        var filename = $(this).val().split('\\').pop();
        var wrapper = $(this).closest('.form-group');
        if (filename) {
            $(wrapper).find('.overlayLabel').addClass('selected');
            $(wrapper).find('.overlayLabel').addClass('hasFile').text(filename);
        } else {
            $(wrapper).find('.overlayLabel').removeClass('selected');
            $(wrapper).find('.overlayLabel').removeClass('hasFile').text('');
        }
    });

    enquire.register("screen and (min-width: 1024px)", {
        match: function () {

            /* langSwitch */

            $('#language .current').siblings().hide();

            $('#language .current').on('click', function (e) {
                e.preventDefault();
                $(this).siblings().toggle();
            });

        },

        unmatch: function () {

            $('#language .current').siblings().show();
            $('#language .current').off();

        }

    });

    /* fancybox for youtube links */

    $('.slider a[href*="youtu"], main a[href*="youtu"]').fancybox();

    /* download center */
    $('.downloads .download-navigation-item').on('click', function () {
        var $me = $(this),
            $downloads = $me.closest('.downloads'),
            uid = $me.data('uid');

        $downloads.find('.download-navigation-item.active').removeClass('active');
        $me.addClass('active');
        $downloads.find('.download-content.active').slideUp(200, function () {
            $(this).removeClass('active');
            $downloads.find('.download-content[data-uid=' + uid + ']').slideDown(500, function () {
                $(this).addClass('active');
            });
        });
    });

    $('.imageTabs .tabHeader a').on('click', function (e) {
        e.preventDefault();

        var $me = $(this),
            target = $me.attr('href');

        $('.imageTabs .tabHeader a').removeClass('active');
        $me.addClass('active');

        $('.imageTabs .tabContent .tab').removeClass('active');
        $('.imageTabs .tabContent ' + target).addClass('active');
    });

    // anchor menu handling
    function isScrolledIntoView(elem) {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();

        var elemTop = $(elem).offset().top + 100;
        var elemBottom = elemTop + $(elem).height();

        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    }

    var anchors = $('main').find('a[id]:not(:link)');
    var anchorScroll = false;
    $(window).on('scroll', function () {
        if (anchorScroll) {
            clearTimeout(anchorScroll);
        }
        anchorScroll = setTimeout(function () {
            $(anchors).each(function (k, anchor) {
                if (isScrolledIntoView(anchor)) {
                    console.log(anchor);
                    $('a[data-anchor="' + $(anchor).attr('id') + '"]').parent().addClass('visible');
                    if ($('.anchorTrigger').height() > 0) {
                        console.log("changing text");
                        //$('.anchorTrigger span').html($('a[data-anchor="' + $(anchor).attr('id') + '"]').text());
                    }
                } else {
                    $('a[data-anchor="' + $(anchor).attr('id') + '"]').parent().removeClass('visible');
                }
            });

            if ($('.anchorTrigger span').html() == '' && $('.anchorTrigger').height() > 0) {
                var anchorLinks = $('.anchorLinks a[data-anchor]');
                var first = $(anchorLinks[0]).text();
                var latest = $(anchorLinks[anchorLinks.length - 1])[0];

                if ($(window).scrollTop() > $('a[id="' + $(latest).attr('data-anchor') + '"]').offset().top - 100) {
                    $('.anchorTrigger span').html($(latest).text());
                } else {
                    $('.anchorTrigger span').html(first);
                }
            }
        }, 401);
    }).trigger('scroll');

    $('.anchorLinks ul a').on('click', function () {
        var anchor = '#' + $(this).attr('href').split('#')[1];
        if (isScrolledIntoView(anchor)) {
            $(anchor).next().find('h1,h2,h3').parent().fadeTo(300, .25, function () {
                $(this).fadeTo(300, 1);
            });
        }
        $('.anchorTrigger').removeClass('open');
        $('#anchorLinks').removeClass('active');
    });

    $('.anchorTrigger').on('click', function () {
        $(this).toggleClass('open');
        $('#anchorLinks').toggleClass('active');
    });

    // $('#anchorTrigger').click(function(){
    //     $('#sel-option').show();
    // });

    // $('.anchorLinks').click(function(e){
    //     $('a').text($(this).text());
    //     $('#sel-option').hide();
    //     $(this).addClass('current');
    //     e.preventDefault();
    // })

    // var anchor_offset = $('a[href="#test"]').offset().top;
    // $(window).on('scroll', function() {
    //     if ( $(window).scrollTop() > anchor_offset )
    //          $('#test').show();
    // });

    // product search
    var results = new Array;
    var options = {
        method: 'POST',
        headers: {
            cookie: 'session-=6m2o9h2uepudp2eefqtjn2fegn; csrf%5Bfrontend.checkout.switch-language%5D=91628449d14a53d4cb5b856.FoWhjU0iB4KeM85j2bfFAC_AAZRx4e7AcJ2BPvoGbJA.XdSZ9wxPTdvMVbo16fqvWVquafEVr4iJEeTjD5gxKKFg3NG6PVBBzstWgw; csrf%5Bfrontend.checkout.configure%5D=d95816570c5f22e912996ebaf.mFTje9LWvDTxsxYOwccc67OxGiWFoEJ3NH5cwJ02QLk.8gaWQ-Ki-2CB_HJNjolS3sv_YmnVmDgjWj8amvVHePbgMbs8pLXzbZfHUw; csrf%5Bfrontend.store-api.proxy%5D=bfe213a28.aMDZNXpaN6NYar71gXLxoxmgbKvfzFn7lcEH9tdEFh8.UO2OUiwvf5A_C9-TzyaI1Xj5AvPnjQi6pqJsj5Uqcm0NqLhdGBhT5Wgj3Q',
            'Content-Type': 'application/json',
            'sw-include-seo-urls': '1',
            'sw-access-key': 'SWSCNPGC6FA3OLDGQDO8GUCK7G'
        },
        // body: '{"limit":50,"sw-include-seo-urls":1,"sort":[{"field":"name","order":"ASC","naturalSorting":true},{"field":"active","order":"DESC"}],"query":[{"score":500,"query":{"type":"contains","field":"name","value":"gnye"}}],"filter":[],"includes":{"product":["id","name","categories","score","seoUrls"],"category":["name"],"seo_url":["seoPathInfo"]},"aggregations":[{"name":"product-categories","type":"terms","field":"categories.name"}]}'
    };

    var setDataList = (data) => {
        console.log(data.elements);
        var datalist = document.getElementById('productSearchList');
        datalist.innerHTML = '';
        data.elements.forEach((element) => {
            console.log(element);
            var option = document.createElement('option');
            option.value = element.name;
            datalist.appendChild(option);
        });
    }

    var setUl = (data) => {
        var ulList = document.getElementById('ul_list');
        data.elements.forEach((element) => {
            console.log(element);
            var liElement = document.createElement('li');
            var link = document.createElement('a');
            link.href = "https://conta-clip.shop/" + element.seoUrls[1].seoPathInfo;
            link.innerHTML = element.name;
            liElement.appendChild(link);
            ulList.appendChild(liElement);

        });

        var query = document.querySelector('#productSearch [name="search"]').value;
        var allSearchElement = document.createElement('li');
        var allLink = document.createElement('a');
        var allIcon = document.createElement('i');
        allIcon.className = "icon-arrow";
        allLink.href = "https://conta-clip.shop/search?search=" + query;
        allLink.innerHTML = "Alle Suchergenisse";
        allLink.target = "_blank";
        allLink.prepend(allIcon);
        allSearchElement.appendChild(allLink);
        ulList.appendChild(allSearchElement);
        console.log(ulList);

    }

    var getResults = (e) => {
        var query = document.querySelector('#productSearch [name="search"]').value;
        console.log(query);
        if (query.length > 2) {
            options.body = '{"limit":10,"sw-include-seo-urls":1,"sort":[{"field":"name","order":"ASC","naturalSorting":true},{"field":"active","order":"DESC"}],"query":[{"score":500,"query":{"type":"contains","field":"name","value":"' + query + '"}}],"filter":[],"includes":{"product":["id","name","categories","score","seoUrls"],"category":["name"],"seo_url":["seoPathInfo"]},"aggregations":[{"name":"product-categories","type":"terms","field":"categories.name"}]}'
            fetch('https://conta-clip.shop/store-api/product', options)
                .then(response => response.json())
                //.then(response => setDataList(response))
                .then(response => setUl(response))
                .catch(err => console.error(err));
        }

    }

    document.querySelector('#productSearch [name="search"]').addEventListener('keyup', getResults);
    /*
    //prototypes for close function, unfinished, there might be a better solution
       document.addEventListener('click', function(e){
           console.log(e.target);
           if(e.target != document.getElementById('searchInput')){
             console.log('yes');
           }
       });

       document.addEventListener('click', function(e){
           console.log(e.target);
           if(e.target != document.getElementById('searchInput')
              ){
             console.log('yes');

             console.log(document.querySelectorAll('#ul_list li'));
             document.querySelectorAll('#ul_list li').forEach((el => {
               console.log(e.target == el);
             }))
           }
       });
   */

});
